import React, { useState } from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/image';
import { Box, CheckBox } from 'akar-icons';

export default function DemoPage() {
    const [form, setForm] = useState({
        api_token: process.env.GATSBY_MAIL_API,
        app_id: process.env.GATSBY_MAIL_APP_ID,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        business: '',
        industry: '',
        message: '',
    });
    const [formClass, setFormClass] = useState({
        firstname: 'formitem',
        lastname: 'formitem',
        email: 'formitem',
        phone: 'formitem',
        business: 'formitem',
        industry: 'formitem',
        message: 'formitem fw',
    });
    const [errorStyle, setErrorStyle] = useState({
        opacity: 0,
        visibility: 'hidden',
        display: 'none',
    });
    const [errorClass, setErrorClass] = useState('formerror');
    const [formVisible, setFormVisible] = useState(true);
    const [agree, setAgree] = useState(false);
    const [agreeClass, setAgreeClass] = useState('consentbtn');
    const [errorMsg, setErrorMsg] = useState(
        'Submission Error! Please check the required fields below and try again.'
    );

    const handleChange = (e) => {
        if (e) {
            setForm((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }));
        }
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();

            if (
                form.firstname !== '' &&
                form.lastname !== '' &&
                form.email !== '' &&
                form.phone !== '' &&
                form.business !== '' &&
                form.industry !== '' &&
                form.message !== '' &&
                agree === true
            ) {
                fetch(process.env.GATSBY_MAIL_URL, {
                    method: 'POST',
                    body: JSON.stringify(form),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }).then(
                    () => {
                        setForm((prevState) => ({
                            ...prevState,
                            firstname: '',
                            lastname: '',
                            email: '',
                            phone: '',
                            business: '',
                            industry: '',
                            message: '',
                        }));

                        setErrorStyle((prevState) => ({
                            ...prevState,
                            opacity: 1,
                            visibility: 'visible',
                            display: 'block',
                        }));
                        setErrorClass('formsuccess');
                        setFormVisible(false);
                        setErrorMsg(
                            'Enquiry successfully submitted. A member of our team will be in-touch soon.'
                        );
                    },
                    (error) => {
                        console.info(error);
                    }
                );
            } else {
                setErrorStyle((prevState) => ({
                    ...prevState,
                    opacity: 1,
                    visibility: 'visible',
                    display: 'block',
                }));
                setErrorClass('formerror');
                setErrorMsg(
                    'Submission Error! Please check the required fields below and try again.'
                );

                if (form.firstname === '') {
                    setFormClass((prevState) => ({
                        ...prevState,
                        firstname: 'formitem inputerror',
                    }));
                }

                if (form.lastname === '') {
                    console.info('a');
                    setFormClass((prevState) => ({
                        ...prevState,
                        lastname: 'formitem inputerror',
                    }));
                }

                if (form.email === '') {
                    setFormClass((prevState) => ({
                        ...prevState,
                        email: 'formitem inputerror',
                    }));
                }

                if (form.business === '') {
                    setFormClass((prevState) => ({
                        ...prevState,
                        business: 'formitem inputerror',
                    }));
                }

                if (form.message === '') {
                    setFormClass((prevState) => ({
                        ...prevState,
                        message: 'formitem fw inputerror',
                    }));
                }

                if (agree === false) {
                    setAgreeClass('consentbtn consenterror');
                }
            }
        }
    };

    return (
        <Layout>
            <Seo
                title="Book a Demo"
                description="Book a personalised demo with DigiClinic to find out how we can help your Clinic."
            />
            <div className="inttop"></div>
            <div className="app-title">
                <div className="container">
                    <div className="title">
                        <div className="title__txt">
                            <h1>
                                Book a Demo{' '}
                                <span>
                                    Find out what Digiclinic can do for your
                                    clinic.
                                </span>
                            </h1>
                            <p>
                                Fill in the details below and one of our
                                friendly customer service team will be in touch
                                as soon as possible.
                            </p>
                        </div>
                        <div className="title__img bookimg">
                            <Image src="title-img.png" alt="Demo" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-content featurebg">
                <div className="container demowrap">
                    <div className={errorClass} style={errorStyle}>
                        <span>{errorMsg}</span>
                    </div>
                    {formVisible === true ? (
                        <div className="demo">
                            <div className="demo__form">
                                <div className={formClass.firstname}>
                                    <label className="fi__label">
                                        <input
                                            type="text"
                                            name="firstname"
                                            value={form.firstname}
                                            onChange={handleChange}
                                            placeholder=" "
                                        />
                                        <span className="fi__span">
                                            First Name <small>required</small>
                                        </span>
                                    </label>
                                </div>
                                <div className={formClass.lastname}>
                                    <label className="fi__label">
                                        <input
                                            type="text"
                                            name="lastname"
                                            value={form.lastname}
                                            onChange={handleChange}
                                            placeholder=" "
                                        />
                                        <span className="fi__span">
                                            Last Name <small>required</small>
                                        </span>
                                    </label>
                                </div>
                                <div className={formClass.email}>
                                    <label className="fi__label">
                                        <input
                                            type="text"
                                            name="email"
                                            value={form.email}
                                            onChange={handleChange}
                                            placeholder=" "
                                        />
                                        <span className="fi__span">
                                            Email <small>required</small>
                                        </span>
                                    </label>
                                </div>
                                <div className={formClass.phone}>
                                    <label className="fi__label">
                                        <input
                                            type="text"
                                            name="phone"
                                            value={form.phone}
                                            onChange={handleChange}
                                            placeholder=" "
                                        />
                                        <span className="fi__span">Phone</span>
                                    </label>
                                </div>
                                <div className={formClass.business}>
                                    <label className="fi__label">
                                        <input
                                            type="text"
                                            name="business"
                                            value={form.business}
                                            onChange={handleChange}
                                            placeholder=" "
                                        />
                                        <span className="fi__span">
                                            Business Name{' '}
                                            <small>required</small>
                                        </span>
                                    </label>
                                </div>
                                <div className={formClass.industry}>
                                    <label className="fi__label">
                                        <input
                                            type="text"
                                            name="industry"
                                            value={form.industry}
                                            onChange={handleChange}
                                            placeholder=" "
                                        />
                                        <span className="fi__span">
                                            Industry
                                        </span>
                                    </label>
                                </div>
                                <div className={formClass.message}>
                                    <label className="fi__label">
                                        <textarea
                                            type="textarea"
                                            name="message"
                                            value={form.message}
                                            onChange={handleChange}
                                            placeholder=" "
                                        />
                                        <span className="fi__span">
                                            Further Details
                                        </span>
                                    </label>
                                </div>
                                <div className="formitem fw consent">
                                    <button className={agreeClass}>
                                        {agree === false ? (
                                            <Box
                                                strokeWidth={2}
                                                size={24}
                                                onClick={() => {
                                                    setAgree(true);
                                                }}
                                            />
                                        ) : (
                                            <CheckBox
                                                strokeWidth={2}
                                                size={24}
                                                onClick={() => {
                                                    setAgree(false);
                                                }}
                                            />
                                        )}
                                    </button>
                                    <span>
                                        I understand that DigiClinic will
                                        contact me using the details provided
                                        above and I can opt out at any time.
                                    </span>
                                </div>
                                <div className="formitem fw">
                                    <button
                                        className="btn"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </Layout>
    );
}
